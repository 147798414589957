/* header */
.header {
	position: relative;
	z-index: 101;
	border-bottom: 2px solid #A0A340;
	background: #fff;
	padding:5px 0;

	.container {
		align-items: center;
		gap:30px;
	}
}

/* menu */
.navigation {
	@media screen and (min-width:992px) {
		overflow: visible;
		flex:1;
		justify-content: center;
	}

	@media screen and (max-width:992px) {
		position: fixed;
		top:88px;
		bottom:0;
		right:0;
		background: #fff;
		width:0;
		padding:25px 0;

		&.open {
			width:360px;
			padding:25px 20px;
		}
	}

	@media screen and (max-width:450px) {
		&.open {
			width:100%;
		}
	}
}

.menu {
	&-link {
		position: relative;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		color:#100F0F;
		text-transform: capitalize;
	}

	&-btn {
		svg {
			fill:#100F0F;
			width:11px;
			min-width:11px;
			height:6px;
		}
	}

	@media screen and (min-width:992px) {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap:25px;

		&-arr {
			position: relative;
			display: flex;
			align-items: center;
			gap:5px;

			&:last-child {
				.downmenu {
					left:auto;
					right:0;
				}
			}

			&:hover {
				.menu {
					&-link {
						color:#A0A340;
					}

					&-btn {
						svg {
							transform: rotate(180deg);
							fill:#A0A340;
						}
					}
				}
	
				.downmenu {
					max-height:1000px;
					overflow: visible;
				}

				.submenu {
					box-shadow: 2px 2px 50px 0px rgba(#000,.1);
				}
			}
		}

		&-link {
			&:hover {
				color:#A0A340;
			}
		}
	}

	@media screen and (min-width:1190px) {
		&-arr {
			&:last-child {
				.downmenu {
					left:calc(50% - 150px);
					right:auto;
				}
			}
		}
	}

	@media screen and (max-width:992px) {
		display: block;
		margin-bottom: 30px;

		&-btn {
			display: flex;
			position: relative;
			align-items: center;
			justify-content: center;
			width:20px;
			min-width:20px;
			height:20px;
			margin-left: auto;
		}

		&-arr {
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			&.open {
				.downmenu {
					max-height: 1000px;
					overflow: visible;
					padding-top: 20px;
				}

				.menu-btn {
					svg {
						transform: rotate(-180deg);
					}
				}
			}
		}

		&__item {
			&:not(:last-child) {
				border-bottom: 1px solid #C7C7C7;
				padding-bottom: 20px;
				margin-bottom: 20px;
			}
		}
	}
}

.downmenu {
	max-height:0;
	overflow: hidden;

	@media screen and (min-width:992px) {
		position: absolute;
		left:calc(50% - 150px);
		z-index: 1;
		top:20px;
		padding-top: 13px;
		transition: padding .7s;
	}

	@media screen and (max-width:992px) {
		min-width:100%;
		padding-top: 0;
	}
}

.submenu {
	margin-bottom: 0;
	margin-left: 0;

	&__item {
		&:not(:last-child) {
			margin-bottom: 10px;
		}
	}

	&-link {
		font-size: 15px;
		font-weight: 400;
		line-height: 23px;
		color:#100F0F;

		&:hover {
			text-decoration: underline;
		}
	}

	@media screen and (min-width:992px) {
		width:300px;
		background: #fff;
		border-radius: 5px;
		padding:20px;
	}
}

/* hamburger */
.hamburger {
	position: relative;
	width:24px;
	height:20px;
	text-align: center;
	cursor: pointer;
	transition-property: opacity, filter;
	transition-duration: 0.15s;
	transition-timing-function: linear;

	&-inner, &-inner::before, &-inner::after {
		position: absolute;
		width: 100%;
		height: 2px;
		border-radius: 1.5px;
		transition-property: transform;
		transition-duration: 0.15s;
		transition-timing-function: ease; 
		background: #000;
	}

	&-inner {
		background: #000;
		display: block;
		top: calc(50% - 1px); 

		&::before, &::after {
			content: "";
			display: block; 
			background: #000;
			right:0;
		}

		&::before {
			width:14px;
			top:6px; 
		}

		&::after {
			width:19px;
			bottom: 6px;
		}
	}

	&.is_active {
		.hamburger-inner {
			transform: rotate(225deg);
			transition-delay: 0.12s;
			transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); 

			&::before, &::after {
				width:24px;
			}

			&::before {
				opacity: 0;
			}

			&::after {
				bottom: 0;
				transform: rotate(-90deg);
				transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
			}
		}
	}

	&:hover {
		opacity: .7;
	}

	@media screen and (min-width:992px) {
		display: none;
	}
}