html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, pre,
a, abbr, address, big, blockquote, cite, code,
del, dfn, em, img, ins, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li, form, label, input, fieldset, legend, caption,
article, aside, canvas, details, embed,
figure, figcaption, footer, header,
menu, main, nav, output, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: none;
  outline: none;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}

html,
body {
  height: 100%;
  width: 100%;
}

body {
  line-height: 1;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

ol, ul, nav, menu {
  list-style: none;
}

img, video, canvas {
  max-width: 100%;
  height: auto;
}

img {
  vertical-align: bottom;
}

a, button {
  cursor: pointer;
  margin: 0;
  padding: 0;
}

a:hover, button:hover {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  border: none;
  background: transparent;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

th, td {
  vertical-align: middle;
}

iframe {
  width: 100%;
}

/* Roboto */
@font-face {
  font-family: "Roboto";
  src: local("Roboto-Regular"), url("../fonts/roboto-regular.woff2") format("woff2");
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto-Italic"), url("../fonts/roboto-italic.woff2") format("woff2");
  font-weight: normal;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto-Medium"), url("../fonts/roboto-500.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto-MediumItalic"), url("../fonts/roboto-500italic.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto-BoldItalic"), url("../fonts/roboto-700italic.woff2") format("woff2");
  font-weight: bold;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto-Bold"), url("../fonts/roboto-700.woff2") format("woff2");
  font-weight: bold;
  font-display: swap;
  font-style: normal;
}
/* =========================== all */
body {
  display: flex;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  color: #1B1919;
  background: #fff;
  line-height: 1.5;
}

.body_scroll {
  overflow: hidden;
}

/* heading */
.h1, .h2, .h3, .h4 {
  font-weight: 700;
  color: #100F0F;
}

.h1 {
  font-size: 42px;
  line-height: 48px;
  margin-bottom: 15px;
}
@media screen and (max-width: 768px) {
  .h1 {
    font-size: 36px;
    line-height: 46px;
  }
}

.h2 {
  font-size: 30px;
  line-height: 38px;
  margin-bottom: 20px;
}

.h3 {
  font-size: 26px;
  line-height: 34px;
  margin-bottom: 15px;
  margin-top: 40px;
}

.h4 {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 15px;
}

.heading_bg {
  border-radius: 5px;
  background: #A0A340;
  color: #fff;
  padding: 0 5px;
}

.section {
  margin-bottom: 40px;
}

/* content */
a, p, ol:not([class]) li, ul:not([class]) li {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

p {
  margin-bottom: 30px;
}

.text p:last-child {
  margin-bottom: 0;
}

a:not([class]) {
  text-decoration: underline;
}
a:not([class]):hover {
  color: #A0A340;
}

b, strong {
  font-weight: bold;
}

i, em {
  font-style: italic;
}

/* list */
ol:not([class]), ul:not([class]) {
  margin-bottom: 15px;
}
ol:not([class]) li, ul:not([class]) li {
  display: table;
  position: relative;
  font-weight: 600;
  padding-left: 36px;
}
ol:not([class]) li::before, ul:not([class]) li::before {
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  border-radius: 5px;
}
ol:not([class]) li:not(:last-child), ul:not([class]) li:not(:last-child) {
  margin-bottom: 8px;
}

table ol:not([class]) li, table ul:not([class]) li, .table ol:not([class]) li, .table ul:not([class]) li {
  display: block;
}

ol:not([class]) {
  counter-reset: number;
}
ol:not([class]) li::before {
  position: absolute;
  counter-increment: number;
  content: counter(number);
  text-align: center;
  line-height: 24px;
  background: #E1E5BA;
  font-size: 15px;
  font-weight: 600;
  color: #100F0F;
}

ul:not([class]) li::before {
  position: absolute;
  content: "";
  width: 24px;
  height: 24px;
  background: center center/cover no-repeat;
  background-image: url("../img/sprite.svg#sprite--check-black");
}

.list-check li {
  position: relative;
  font-size: 15px;
  line-height: 23px;
  padding-left: 23px;
}
.list-check li::before {
  position: absolute;
  content: "";
  width: 16px;
  height: 16px;
  background: center center/cover no-repeat;
  background-image: url("../img/sprite.svg#sprite--check-green");
  left: 0;
  top: 3.5px;
}

/* img */
img {
  display: block;
  margin: 0 auto;
}

.img {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 5px;
}

.figure {
  margin-bottom: 15px;
}

/* transition */
* {
  transition: all 0.3s;
}

/* btn */
.btn {
  display: block;
  height: 43px;
  line-height: 43px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  overflow: hidden;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  margin: 0 auto;
}
.btn-arrow {
  position: relative;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  padding-right: 18px;
}
.btn-arrow::before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  background: center center/cover no-repeat;
  background-image: url("../img/sprite.svg#sprite--play-icon");
  right: 0;
  top: calc(50% - 5px);
}

.btn_play {
  width: 120px;
  background: #E51937;
}
.btn_play:hover {
  background: #C0001C;
}

.btn_visit {
  background: #A0A340;
  width: 220px;
}
.btn_visit:hover {
  background: #828526;
}

@media screen and (max-width: 450px) {
  .btn_mob {
    width: 100%;
  }
}

/* flexbox */
.flexbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* gridbox */
.gridbox {
  display: grid;
  justify-content: space-between;
}

/* rating */
.rating {
  width: 80px;
  gap: 5px;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  border-radius: 5px;
  border: 1px solid #E1E1E1;
  padding: 8px 10px;
}
.rating-star img {
  width: 14px;
  height: 14px;
}
.rating-total {
  font-size: 12px;
  font-weight: 400;
  line-height: 23px;
}
.rating-total-b {
  font-size: 15px;
  font-weight: 700;
  line-height: 23px;
}

/* block */
.overly {
  display: none;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
}

/* scroll */
.scroll {
  overflow: auto;
  -ms-scroll-chaining: none;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: thin;
  scrollbar-color: #DCDFE9 transparent;
}

.scroll::-webkit-scrollbar {
  width: 4px;
  height: 50%;
}

.scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #DCDFE9;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  min-height: 50%;
  min-width: 4px;
}

.scroll::-webkit-scrollbar-thumb:hover {
  background-color: #DCDFE9;
}

.scroll::-webkit-scrollbar-thumb:active {
  background-color: #DCDFE9;
}

/* table */
.table_scroll {
  margin-bottom: 20px;
}
.table_scroll .table {
  margin-bottom: 0;
}
@media screen and (max-width: 768px) {
  .table_scroll {
    width: calc(100% + 20px);
    margin-right: -20px;
  }
  .table_scroll .table {
    width: auto;
  }
}

.table {
  border-radius: 5px;
  border: 1px solid #E1E1E1;
  border-collapse: separate;
  background: #fff;
  overflow: hidden;
  margin-bottom: 30px;
}
.table tr:not(:last-child) td {
  border-bottom: 1px solid #E1E1E1;
}
.table th, .table td {
  vertical-align: middle;
  line-height: normal;
  text-align: left;
  padding: 12px 15px;
}
.table th:not(:last-child), .table td:not(:last-child) {
  border-right: 1px solid #E1E1E1;
}
.table th {
  background: #EEEEEE;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #100F0F;
  text-transform: uppercase;
}
.table td {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
}
.table a {
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  text-decoration: none;
  color: #A0A340;
  white-space: nowrap;
}
.table a:hover {
  text-decoration: underline;
}
.table-characteristics td {
  width: 1%;
}
.table-characteristics b {
  position: relative;
  display: inline-block;
  padding-left: 20px;
}
.table-characteristics b::before {
  position: absolute;
  content: "";
  width: 7px;
  height: 12px;
  background: center center/cover no-repeat;
  background-image: url("../img/sprite.svg#sprite--arrow-vertical");
  top: calc(50% - 6px);
  left: 0;
}

button {
  display: inline-block;
  cursor: pointer;
  font-family: "Roboto";
}

/* container */
.container {
  max-width: 990px;
  padding: 0 20px;
  margin: 0 auto;
}

main {
  flex: 1;
  padding-bottom: 30px;
}
@media screen and (max-width: 768px) {
  main {
    padding-bottom: 64px;
  }
}

/* header */
.header {
  position: relative;
  z-index: 101;
  border-bottom: 2px solid #A0A340;
  background: #fff;
  padding: 5px 0;
}
.header .container {
  align-items: center;
  gap: 30px;
}

/* menu */
@media screen and (min-width: 992px) {
  .navigation {
    overflow: visible;
    flex: 1;
    justify-content: center;
  }
}
@media screen and (max-width: 992px) {
  .navigation {
    position: fixed;
    top: 88px;
    bottom: 0;
    right: 0;
    background: #fff;
    width: 0;
    padding: 25px 0;
  }
  .navigation.open {
    width: 360px;
    padding: 25px 20px;
  }
}
@media screen and (max-width: 450px) {
  .navigation.open {
    width: 100%;
  }
}

.menu-link {
  position: relative;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #100F0F;
  text-transform: capitalize;
}
.menu-btn svg {
  fill: #100F0F;
  width: 11px;
  min-width: 11px;
  height: 6px;
}
@media screen and (min-width: 992px) {
  .menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 25px;
  }
  .menu-arr {
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .menu-arr:last-child .downmenu {
    left: auto;
    right: 0;
  }
  .menu-arr:hover .menu-link {
    color: #A0A340;
  }
  .menu-arr:hover .menu-btn svg {
    transform: rotate(180deg);
    fill: #A0A340;
  }
  .menu-arr:hover .downmenu {
    max-height: 1000px;
    overflow: visible;
  }
  .menu-arr:hover .submenu {
    box-shadow: 2px 2px 50px 0px rgba(0, 0, 0, 0.1);
  }
  .menu-link:hover {
    color: #A0A340;
  }
}
@media screen and (min-width: 1190px) {
  .menu-arr:last-child .downmenu {
    left: calc(50% - 150px);
    right: auto;
  }
}
@media screen and (max-width: 992px) {
  .menu {
    display: block;
    margin-bottom: 30px;
  }
  .menu-btn {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-left: auto;
  }
  .menu-arr {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .menu-arr.open .downmenu {
    max-height: 1000px;
    overflow: visible;
    padding-top: 20px;
  }
  .menu-arr.open .menu-btn svg {
    transform: rotate(-180deg);
  }
  .menu__item:not(:last-child) {
    border-bottom: 1px solid #C7C7C7;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}

.downmenu {
  max-height: 0;
  overflow: hidden;
}
@media screen and (min-width: 992px) {
  .downmenu {
    position: absolute;
    left: calc(50% - 150px);
    z-index: 1;
    top: 20px;
    padding-top: 13px;
    transition: padding 0.7s;
  }
}
@media screen and (max-width: 992px) {
  .downmenu {
    min-width: 100%;
    padding-top: 0;
  }
}

.submenu {
  margin-bottom: 0;
  margin-left: 0;
}
.submenu__item:not(:last-child) {
  margin-bottom: 10px;
}
.submenu-link {
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  color: #100F0F;
}
.submenu-link:hover {
  text-decoration: underline;
}
@media screen and (min-width: 992px) {
  .submenu {
    width: 300px;
    background: #fff;
    border-radius: 5px;
    padding: 20px;
  }
}

/* hamburger */
.hamburger {
  position: relative;
  width: 24px;
  height: 20px;
  text-align: center;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  position: absolute;
  width: 100%;
  height: 2px;
  border-radius: 1.5px;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
  background: #000;
}
.hamburger-inner {
  background: #000;
  display: block;
  top: calc(50% - 1px);
}
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
  background: #000;
  right: 0;
}
.hamburger-inner::before {
  width: 14px;
  top: 6px;
}
.hamburger-inner::after {
  width: 19px;
  bottom: 6px;
}
.hamburger.is_active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger.is_active .hamburger-inner::before, .hamburger.is_active .hamburger-inner::after {
  width: 24px;
}
.hamburger.is_active .hamburger-inner::before {
  opacity: 0;
}
.hamburger.is_active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger:hover {
  opacity: 0.7;
}
@media screen and (min-width: 992px) {
  .hamburger {
    display: none;
  }
}

/* promo */
.promo {
  background: linear-gradient(360deg, rgba(225, 229, 186, 0) 0%, rgba(238, 243, 198, 0.6) 100%);
  padding: 50px 0 40px;
}
.promo p:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 768px) {
  .promo {
    background: none;
    padding-bottom: 35px;
  }
}

/* offer */
.offer {
  counter-reset: number;
  margin-bottom: 50px;
}
.offer-th {
  background: #F5F8DE;
  font-size: 14px;
  font-weight: 500;
  line-height: 23px;
  text-transform: uppercase;
  color: #100F0F;
  padding: 12px 10px;
}
.offer-th:first-child {
  border-radius: 5px 0 0 5px;
  padding-left: 10px;
}
.offer-th:last-child {
  border-radius: 0 5px 5px 0;
  padding-right: 10px;
}
.offer-head .offer-bonus, .offer-head .offer-characteristics {
  text-align: left;
}
.offer__item {
  border-bottom: 1px solid #E1E1E1;
}
.offer-td {
  padding: 15px 10px;
}
.offer-td:first-child {
  padding-left: 0;
}
.offer-td:last-child {
  padding-right: 0;
}
.offer .img {
  position: relative;
  width: 140px;
  height: 100px;
  margin-bottom: 8px;
}
.offer .img::before {
  position: absolute;
  counter-increment: number;
  content: counter(number);
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background: #F5F8DE;
  text-align: center;
  line-height: 22px;
  font-size: 12px;
  font-weight: 600;
  color: #1B1919;
  top: 4px;
  left: 4px;
}
.offer-logo {
  text-align: center;
}
.offer-name {
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  color: #100F0F;
  text-transform: capitalize;
}
.offer-name:hover {
  text-decoration: underline;
}
.offer .bonus {
  position: relative;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #100F0F;
  padding-left: 25px;
  margin-bottom: 0;
}
.offer .bonus::before {
  position: absolute;
  content: "";
  width: 17px;
  height: 20px;
  background: center center/cover no-repeat;
  background-image: url("../img/sprite.svg#sprite--bonus-icon");
  top: 0;
  left: 0;
}
@media screen and (min-width: 768px) {
  .offer__item:first-child .offer-td {
    padding-top: 20px;
  }
  .offer-logo {
    width: 150px;
    min-width: 150px;
  }
  .offer-bonus {
    max-width: 260px;
  }
  .offer-rating {
    width: 100px;
    min-width: 100px;
  }
}
@media screen and (max-width: 768px) {
  .offer {
    display: block;
  }
  .offer thead {
    display: none;
  }
  .offer tbody, .offer__item, .offer-td {
    display: block;
  }
  .offer__item {
    padding-bottom: 20px;
  }
  .offer__item:not(:last-child) {
    margin-bottom: 20px;
  }
  .offer-td {
    padding: 0;
  }
  .offer-td:not(:last-child) {
    padding-bottom: 15px;
  }
  .offer .img {
    width: 320px;
    height: 140px;
    margin: 0 auto;
    margin-bottom: 12px;
  }
  .offer .rating, .offer .btn_play {
    width: 100%;
  }
  .offer-name {
    font-size: 14px;
  }
}
@media screen and (max-width: 450px) {
  .offer .img {
    width: 100%;
  }
}

/* faq */
.faq__item:not(:last-child) {
  border-bottom: 1px solid #E1E1E1;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.faq-heading {
  position: relative;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  color: #100F0F;
  padding-left: 34px;
  margin-bottom: 15px;
}
.faq-heading::before {
  position: absolute;
  content: "";
  width: 24px;
  height: 24px;
  background: center center/cover no-repeat;
  background-image: url("../img/sprite.svg#sprite--faq-icon");
  left: 0;
  top: 0;
}
@media screen and (max-width: 768px) {
  .faq__item:not(:last-child) {
    padding-bottom: 20px;
  }
  .faq-heading {
    margin-bottom: 20px;
  }
}

/* footer */
.footer {
  border-top: 2px solid #A0A340;
}
.footer_top {
  gap: 20px 30px;
  padding: 40px 0;
}
.footer_bottom {
  align-items: center;
  gap: 20px 30px;
  padding-bottom: 40px;
}
.footer-navigation {
  gap: 20px 30px;
  justify-content: center;
}
.footer-navigation__item {
  flex: 1;
  min-width: 215px;
}
.footer-heading {
  color: #100F0F;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 15px;
}
.footer-list__item {
  line-height: 22px;
}
.footer-list__item:not(:last-child) {
  margin-bottom: 10px;
}
.footer-list-link {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: #1B1919;
}
.footer-list-link:hover {
  text-decoration: underline;
}
@media screen and (max-width: 890px) {
  .footer-navigation__item {
    flex: 1;
    min-width: 150px;
  }
}
@media screen and (max-width: 768px) {
  .footer_top, .footer_bottom {
    justify-content: center;
  }
  .footer_top {
    padding-bottom: 30px;
  }
  .footer-navigation {
    min-width: 100%;
  }
  .footer-logo {
    margin: 16px 0;
  }
}
@media screen and (max-width: 450px) {
  .footer-navigation__item {
    min-width: 100%;
    text-align: center;
  }
}

.reliable {
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
}
.reliable img {
  margin: 0;
}
@media screen and (max-width: 768px) {
  .reliable {
    justify-content: center;
    gap: 20px;
  }
}

.copy {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(27, 25, 25, 0.5);
}