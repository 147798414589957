/* offer */
.offer {
    counter-reset: number;
    margin-bottom: 50px;

    &-th {
        background: #F5F8DE;
        font-size: 14px;
        font-weight: 500;
        line-height: 23px;
        text-transform: uppercase;
        color:#100F0F;
        padding:12px 10px;

        &:first-child {
            border-radius: 5px 0 0 5px;
            padding-left: 10px;
        }

        &:last-child {
            border-radius: 0 5px 5px 0;
            padding-right: 10px;
        }
    }

    &-head {
        .offer {
            &-bonus, &-characteristics {
                text-align: left;
            }
        }
    }

    &__item {
        border-bottom: 1px solid #E1E1E1;
    }

    &-td {
        padding:15px 10px;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    .img {
        position: relative;
        width:140px;
        height:100px;
        margin-bottom: 8px;

        &::before {
            position: absolute;
            counter-increment: number;
            content:counter(number);
            width:22px;
            height:22px;
            border-radius: 100%;
            background: #F5F8DE;
            text-align: center;
            line-height: 22px;
            font-size: 12px;
            font-weight: 600;
            color:#1B1919;
            top:4px;
            left:4px;
        }
    }

    &-logo {
        text-align: center;
    }

    &-name {
        font-size: 13px;
        font-weight: 500;
        line-height: 16px;
        color:#100F0F;
        text-transform: capitalize;

        &:hover {
            text-decoration: underline;
        }
    }

    .bonus {
        position: relative;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        color:#100F0F;
        padding-left: 25px;
        margin-bottom: 0;

        &::before {
            position: absolute;
            content:"";
            @include sprite(17px,20px,"../img/sprite.svg#sprite--bonus-icon");
            top:0;
            left:0;
        }
    }

    @media screen and (min-width:768px) {
        &__item {
            &:first-child {
                .offer {
                    &-td {
                        padding-top: 20px;
                    }
                }
            }
        }

        &-logo {
            width:150px;
            min-width:150px;
        }
    
        &-bonus {
            max-width:260px;
        }
    
        &-rating {
            width:100px;
            min-width:100px;
        }
    }

    @media screen and (max-width:768px) {
        display: block;

        thead {
            display: none;
        }

        tbody, &__item, &-td {
            display: block;
        }

        &__item {
            padding-bottom: 20px;

            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }

        &-td {
            padding:0;

            &:not(:last-child) {
                padding-bottom: 15px;
            }
        }

        .img {
            width:320px;
            height:140px;
            margin:0 auto;
            margin-bottom: 12px;
        }

        .rating, .btn_play {
            width:100%;
        }

        &-name {
            font-size: 14px;
        }
    }

    @media screen and (max-width:450px) {
        .img {
            width:100%;
        }
    }
}