/* Roboto */
@font-face {
  font-family: "Roboto";
  src: local("Roboto-Regular"),
        url("../fonts/roboto-regular.woff2") format("woff2");
  font-weight: normal;
  font-display: swap;
  font-style: normal;}
@font-face {
  font-family: "Roboto";
  src: local("Roboto-Italic"),
        url("../fonts/roboto-italic.woff2") format("woff2");
  font-weight: normal;
  font-display: swap;
  font-style: italic;}
  @font-face {
  font-family: "Roboto";
  src: local("Roboto-Medium"),
        url("../fonts/roboto-500.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;}
@font-face {
  font-family: "Roboto";
  src: local("Roboto-MediumItalic"),
        url("../fonts/roboto-500italic.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: italic;}
@font-face {
  font-family: "Roboto";
  src: local("Roboto-BoldItalic"),
        url("../fonts/roboto-700italic.woff2") format("woff2");
  font-weight: bold;
  font-display: swap;
  font-style: italic;}
@font-face {
  font-family: "Roboto";
  src: local("Roboto-Bold"),
        url("../fonts/roboto-700.woff2") format("woff2");
  font-weight: bold;
  font-display: swap;
  font-style: normal;}
