@mixin sprite($width, $height, $icon) {
  width:$width;
  height:$height;
  background: center center / cover no-repeat;
  background-image: url($icon);
}

@mixin placeholder {
	&::placeholder {@content}
  	&::-webkit-input-placeholder {@content}
  	&:-moz-placeholder {@content}
  	&::-moz-placeholder {@content}
  	&:-ms-input-placeholder {@content}  
}