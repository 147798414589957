/* promo */
.promo {
    background: linear-gradient(360deg, rgba(225, 229, 186, 0) 0%, rgba(238, 243, 198, 0.6) 100%);
    padding:50px 0 40px;

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and (max-width:768px) {
        background: none;
        padding-bottom: 35px;
    }
}