/* =========================== all */
body {
	display: flex;
	flex-direction: column;
	font-family: "Roboto", sans-serif;
	color: #1B1919;
	background: #fff;
	line-height: 1.5;
}

.body_scroll {
	overflow: hidden;
}

/* heading */
.h1,.h2,.h3,.h4 {
	font-weight: 700;
	color:#100F0F;
}

.h1 {
	font-size: 42px;
	line-height: 48px;
	margin-bottom: 15px;

	@media screen and (max-width: 768px) {
		font-size: 36px;
		line-height: 46px;
	}
}

.h2 {
	font-size: 30px;
	line-height: 38px;
	margin-bottom: 20px;
}

.h3 {
	font-size: 26px;
	line-height: 34px;
	margin-bottom: 15px;
	margin-top: 40px;
}

.h4 {
	font-size: 24px;
	line-height: 32px;
	margin-bottom: 15px;
}

.heading_bg {
	border-radius: 5px;
	background: #A0A340;
	color:#fff;
	padding:0 5px;
}

.section {
	margin-bottom: 40px;
}

/* content */
a, p, ol:not([class]) li, ul:not([class]) li {
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
}

p {
	margin-bottom:30px;
}

.text {
	p {
		&:last-child {
			margin-bottom: 0;
		}
	}
}

a:not([class]) {
	text-decoration: underline;

	&:hover {
		color: #A0A340;
	}
}

b, strong {
	font-weight:bold;
}

i, em {
	font-style:italic;
}

/* list */
ol:not([class]), ul:not([class]) {
	margin-bottom: 15px;

	li {
		display:table;
		position: relative;
		font-weight: 600;
		padding-left: 36px;

		&::before{
			left:0;
			top:0;
			width:24px;
			height:24px;
			border-radius: 5px;
		}

		&:not(:last-child) {
			margin-bottom: 8px;
		}
	}
}

table, .table {
	ol:not([class]) li, ul:not([class]) li {
		display: block;
	}
}

ol:not([class]) {
	counter-reset: number;

	li {
		&::before {
			position: absolute;
			counter-increment: number;
			content: counter(number); 
			text-align: center;
			line-height: 24px;
			background: #E1E5BA;
			font-size: 15px;
			font-weight: 600;
			color:#100F0F;
		}
	}
}

ul:not([class]){
	li {
		&::before {
			position: absolute;
			content: "";
			@include sprite(24px,24px,"../img/sprite.svg#sprite--check-black");
		}
	}
}

.list-check {
	li {
		position: relative;
		font-size: 15px;
		line-height: 23px;
		padding-left: 23px;

		&::before {
			position: absolute;
			content:"";
			@include sprite(16px,16px,"../img/sprite.svg#sprite--check-green");
			left:0;
			top:3.5px;
		}
	}
}

/* img */
img {
	display:block;
	margin:0 auto;
}

.img {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius:5px;
	padding:5px;
}

.figure {
	margin-bottom: 15px;
}

/* transition */
* {
	transition:all .3s;
}

/* btn */
.btn {
	display: block;
	height:43px;
	line-height: 43px;
	border-radius:5px;
	cursor:pointer;
	text-align: center;
	text-decoration: none;
	overflow: hidden;
	font-size: 14px;
	font-weight: 700;
	text-transform: uppercase;
	color:#fff;
	margin:0 auto;

	&-arrow {
		position: relative;
		font-size: inherit;
		font-weight: inherit;
		color:inherit;
		padding-right: 18px;

		&::before {
			position: absolute;
			content:"";
			@include sprite(10px,10px,"../img/sprite.svg#sprite--play-icon");
			right:0;
			top:calc(50% - 5px);
		}
	}
}

.btn_play {
	width:120px;
	background: #E51937;

	&:hover {
		background: #C0001C;
	}
}

.btn_visit {
	background: #A0A340;
	width:220px;

	&:hover {
		background: #828526;
	}
}

.btn_mob {
	@media screen and (max-width:450px) {
		width:100%;
	}
}

/* flexbox */
.flexbox {
	display: flex;
	flex-wrap:wrap;
	justify-content: space-between;
}

/* gridbox */
.gridbox {
	display: grid;
	justify-content: space-between;
}

/* rating */
.rating {
	width:80px;
	gap:5px;
	align-items: center;
	justify-content: center;
	flex-wrap:nowrap;
	border-radius: 5px;
	border: 1px solid #E1E1E1;
	padding:8px 10px;

	&-star {
		img {
			width:14px;
			height:14px;
		}
	}

	&-total {
		font-size: 12px;
		font-weight: 400;
		line-height: 23px;

		&-b {
			font-size: 15px;
			font-weight: 700;
			line-height: 23px;
		}
	}
}

/* block */
.overly {
	display:none;
	position: fixed;
	z-index:100;
	top:0;
	left:0;
	right:0;
	bottom:0;
	background:rgba(#000,.4);
}

/* scroll */
.scroll {
	overflow: auto;
	-ms-scroll-chaining: none;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
	scrollbar-width: thin;
	scrollbar-color: #DCDFE9 transparent;
}

.scroll::-webkit-scrollbar {
	width: 4px;
	height: 50%;
}

.scroll::-webkit-scrollbar-track {
	background-color: transparent; 
}

.scroll::-webkit-scrollbar-thumb {
	background-color: #DCDFE9;
	-webkit-border-radius: 5px;
	border-radius: 5px; 
	min-height: 50%;
	min-width: 4px; 
}

.scroll::-webkit-scrollbar-thumb:hover {
	background-color: #DCDFE9; 
}

.scroll::-webkit-scrollbar-thumb:active {
	background-color: #DCDFE9; 
}

/* table */
.table_scroll {
	margin-bottom: 20px;

	.table {
		margin-bottom: 0;
	}

	@media screen and (max-width:768px) {
		width:calc(100% + 20px);
		margin-right: -20px;

		.table {
			width:auto;
		}
	}
}

.table {
	border-radius: 5px;
	border: 1px solid #E1E1E1;
	border-collapse: separate;
	background: #fff;
	overflow: hidden;
	margin-bottom: 30px;

	tr {
		&:not(:last-child) {
			td {
				border-bottom: 1px solid #E1E1E1;
			}
		}
	}

	th, td {
		vertical-align: middle;
		line-height: normal;
		text-align: left;
		padding:12px 15px;

		&:not(:last-child) {
			border-right: 1px solid #E1E1E1;
		}
	}

	th {
		background: #EEEEEE;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		color:#100F0F;
		text-transform: uppercase;
	}

	td {
		font-weight: 400;
		font-size: 15px;
		line-height: 22px;
	}

	a {
		font-weight: 700;
		font-size: 15px;
		line-height: 22px;
		text-decoration: none;
		color:#A0A340;
		white-space: nowrap;

		&:hover {
			text-decoration: underline;
		}
	}

	&-characteristics {
		td {
			width:1%;
		}

		b {
			position: relative;
			display: inline-block;
			padding-left: 20px;

			&::before {
				position: absolute;
				content:"";
				@include sprite(7px,12px,"../img/sprite.svg#sprite--arrow-vertical");
				top:calc(50% - 6px);
				left:0;
			}
		}
	}
}

button {
	display: inline-block;
	cursor: pointer;
	font-family: "Roboto";
}


/* container */
.container {
	max-width:990px;
	padding:0 20px;
	margin:0 auto;
}

main {
	flex:1;
	padding-bottom: 30px;

	@media screen and (max-width:768px) {
		padding-bottom: 64px;
	}
}
