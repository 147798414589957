/* faq */
.faq {
	&__item {
		&:not(:last-child) {
			border-bottom: 1px solid #E1E1E1;
			padding-bottom: 15px;
			margin-bottom: 15px;
		}
	}

	&-heading {
		position: relative;
		font-size: 20px;
		font-weight: 600;
		line-height: 26px;
		color:#100F0F;
		padding-left: 34px;
		margin-bottom: 15px;

		&::before {
			position: absolute;
			content:"";
			@include sprite(24px,24px,"../img/sprite.svg#sprite--faq-icon");
			left:0;
			top:0;
		}
	}

	@media screen and (max-width:768px) {
		&__item {
			&:not(:last-child) {
				padding-bottom: 20px;
			}
		}

		&-heading {
			margin-bottom: 20px;
		}
	}
}