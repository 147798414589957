/* footer */
.footer {
	border-top: 2px solid #A0A340;

	&_top {
		gap:20px 30px;
		padding:40px 0;
	}

	&_bottom {
		align-items: center;
		gap:20px 30px;
		padding-bottom: 40px;
	}

	&-navigation {
		gap:20px 30px;
		justify-content: center;

		&__item {
			flex:1;
			min-width:215px;
		}
	}

	&-heading {
		color:#100F0F;
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
		margin-bottom: 15px;
	}

	&-list {
		&__item {
			line-height: 22px;

			&:not(:last-child) {
				margin-bottom: 10px;
			}
		}

		&-link {
			font-size: 15px;
			font-weight: 400;
			line-height: 22px;
			color:#1B1919;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	@media screen and (max-width:890px) {
		&-navigation {
			&__item {
				flex:1;
				min-width:150px;
			}
		}
	}

	@media screen and (max-width:768px) {
		&_top, &_bottom {
			justify-content: center;
		}

		&_top {
			padding-bottom: 30px;
		}

		&-navigation {
			min-width:100%;
		}

		&-logo {
			margin:16px 0;
		}
	}

	@media screen and (max-width:450px) {
		&-navigation {
			&__item {
				min-width:100%;
				text-align: center;
			}
		}
	}
}

.reliable {
	justify-content: flex-start;
	align-items: center;
	gap:25px;

	img {
		margin:0;
	}

	@media screen and (max-width:768px) {
		justify-content: center;
		gap:20px;
	}
}

.copy {
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	color:rgba(#1B1919,.5);
}